import { graphql, PageProps } from "gatsby";

import React, { useEffect } from "react";

import RecipePreviewCarousel from "@components/recipes/recipePreviewCarousel";
import { HeroCarousel } from "@global";
import CategoryPreview from "@shared/categoryPreview";
import Hero from "@shared/hero";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import Layout from "@shared/layout";
import OurRange from "@shared/ourRange";
import SEO from "@shared/seo";
import WhatsNew from "@shared/whatsNew";
import { useStore } from "@state/store";
import { Container } from "@util/standard";
import queryString from "query-string";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const HomepageTemplate = (props: Props) => {
  const homeQuery = props.data.allSanityHomePage.nodes[0];
  //Check url params
  useEffect(() => {
    let parsedSearch = queryString.parse(location.search);

    if (parsedSearch && parsedSearch.signup) {
      //Set global search state
      useStore.setState({
        visibility: {
          loginDrawerVisible: true,
          cartDrawerVisible: false,
        },
      });
    }
  }, []);

  if (!homeQuery) return null;
  const {
    seo,
    hero,
    additionalHeros,
    featuredProducts,
    featuredProductsAu,
    ourStory,
    ourRange,
    featuredRecipes,
    gifting,
    whatsNew,
  } = homeQuery;

  return (
    <>
      {seo && <SEO seoData={seo} />}
      <Layout>
        <Container flexDirection="column">
          {hero && additionalHeros ? (
            <HeroCarousel
              hero={hero}
              additionalHeros={additionalHeros}
              homeHero
            />
          ) : (
            <Hero homeHero sanityHero={hero} />
          )}
          {Boolean(featuredProducts?.length) && (
            <CategoryPreview
              isFeatured
              products={featuredProducts}
            />
          )}
          {Boolean(featuredProductsAu?.length) && (
            <CategoryPreview
              isFeatured
              products={featuredProductsAu}
            />
          )}
          {ourStory && (
            <ImageTextBlockWithCarousel singleBlockData={ourStory} />
          )}
          {ourRange && <OurRange data={ourRange} />}
          {Boolean(featuredRecipes?.length) && (
            <RecipePreviewCarousel recipes={featuredRecipes!} />
          )}
          {gifting && (
            <ImageTextBlockWithCarousel
              singleBlockData={gifting.giftingBlock}
              customHeight="650px"
            />
          )}
          {Boolean(whatsNew?.length) && <WhatsNew data={whatsNew} />}
        </Container>
      </Layout>
    </>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query allHomepageQuery($iban: String) {
    allSanityHomePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        ourStory {
          ...sanityImageTextBlock
        }
        ourRange {
          _key
          heading
          title
          backgroundImage {
            ...sanityImageFullWidth
          }
          collections {
            main {
              title
              slug {
                current
              }
              hero {
                videoUrl {
                  image {
                    ...sanityImageFullWidth
                  }
                }
              }
            }
          }
        }
        featuredRecipes {
          _id
          id
          info {
            ...sanityRecipeInfo
          }
        }
        gifting {
          ...sanityGiftingLanding
        }
        featuredProducts {
          ...sanityProductPreview
        }
        featuredProductsAu {
          ...sanityProductPreviewAu
        }
        whatsNew {
          ...sanityWhatsNew
        }
        imageSlider {
          ...sanityImageSlider
        }
      }
    }
  }
`;
